import React, { MouseEventHandler, useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";

import Logo from "./Logo";

import mainNavItems from "./MainNav.json"

import './MainNav.scss';


interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

interface MainNavProps {
    onItemClick: MouseEventHandler
}

function MainNav(mainNavProps: MainNavProps) {

    const [displayInstallPwa, setDisplayInstallPwa] = useState(false);
    const [deferredPrompt, setInstallPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
    const location = useLocation().pathname;

    useEffect(() => {
        const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            event.preventDefault();
            // Save the event because you'll need to trigger it later.
            setInstallPromptEvent(event);
            // Show your customized install prompt for your PWA
            // Your own UI doesn't have to be a single element, you
            // can have buttons in different locations, or wait to prompt
            // as part of a critical journey.
            setDisplayInstallPwa(true);
          };
          window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      
          return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
          };
    }, []);

    const installPwa = async () => {
        if (deferredPrompt != null) {
          // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
          deferredPrompt.prompt();
        
          // Find out whether the user confirmed the installation or not
          const { outcome } = await deferredPrompt.userChoice;
          // The deferredPrompt can only be used once.
          //deferredPrompt = null;
          // Act on the user's choice
          if (outcome === 'accepted') {
            console.log('User accepted the install prompt.');
          } else if (outcome === 'dismissed') {
            console.log('User dismissed the install prompt');
          }
        }
      }

    return (
        <div className={"main-nav " + (location === '/' ? 'for-home' : '')}>
            <Link to={location === '/' ? '#main-header' : '/'} className="logo-container" onClick={mainNavProps.onItemClick}>
                <Logo />
            </Link>
            <nav>
                <ul>
                    {
                        mainNavItems.map((mainNavItem) => {
                            return <li>
                                <Link to={location === mainNavItem.url ? '#top' : mainNavItem.url} onClick={mainNavProps.onItemClick}>{mainNavItem.title}</Link>
                                {location === mainNavItem.url && mainNavItem.children &&
                                    <ul>
                                        {
                                            mainNavItem.children.map((subItem) => {
                                                return <li>
                                                    <a href={subItem.url}>{subItem.title}</a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </li>
                        })
                    }
                    <li className={'pwa-item' + (displayInstallPwa ? ' active' : '')}>
                        <button onClick={() => { installPwa(); }}>Installer l'application</button>
                    </li>
                </ul>
            </nav>
            {
                location === '/' &&
                <div className="home-speach">
                    Bienvenue dans l'application dédiée à la résidence Georges Clémenceau, 43 & 45 boulevard de l'Yser.<br />
                    Défiler vers le bas de cette page pour voir les infos à la une ou naviguez en cliquant dans l'un des liens du menu ci-dessus.<br />
                </div>
            }
        </div>
    );
}

export default MainNav;
import React from 'react';

import './Reglement.scss';

function Reglement() {

	return (
		<div className="page reglement" id="reglement-interieur">
			<h1 id="top">REGLEMENT INTERIEUR DE LA RESIDENCE LE CLEMENCEAU C & D</h1>
			<p>
				Dispositions applicables à tous les occupants de l'immeuble, propriétaires et locataires.
				Chaque occupant pourra user librement des parties communes à condition de ne pas faire
				obstacle aux droits des autres occupants sous réserve des règles exceptions et limitations
				ci-après stipulées.
			</p>
			<div className="chapter" id="tranquilite-hygiene-harmonie-bruit">
				<h2>Tranquillité – hygiène - harmonie Bruit</h2>
				<p>
					Tout bruit ou tapage pouvant troubler la tranquillité des occupants est formellement interdit,
					notamment avant 7h30 et après 22h00. Dans ce créneau horaire, le volume sonore des appareils de
					radio, de TV et de hi-fi devra être notablement réduit. De même, il est demandé aux occupants de
					veiller à ne pas claquer les portes, de jour comme de nuit. Les volets roulants doivent être
					actionnés de manière qu’ils soient le moins bruyants possible. En ce qui concerne les travaux
					réalisés par des entreprises, ils doivent avoir lieu uniquement en semaine, du lundi au vendredi
					de 8h00 à 12h30 et de 13h30 à 18h30 et le samedi de 9h00 à 12h00 et de 14h00 à 18h00. Il est
					interdit de réaliser des travaux le dimanche. Rappel règlementation
					(extrait http:/vosdroits.service-public.fr/particuliers/f612.xhtml)
					Si l’auteur des bruits agit de nuit (tapage nocturne) ou s’il commet des nuisances sonores
					injurieuses en plein jour (insultes notamment), vous pouvez faire appel aux forces de l’ordre
					(police, gendarmerie) pour constater le trouble. Une amende forfaitaire peut alors lui être
					infligée à hauteur de 68 € si l’auteur des troubles règle l’amende immédiatement ou dans les
					45 jours suivant le constat d’infraction (ou l’envoi de l’avis d’infraction le cas échéant)
					et 180 € au-delà de ce délai. Les enfants ne doivent pas faire de bruit dans les couloirs
				</p>
				<p>
					Les animaux criards sont interdits dans l’immeuble. Les chiens et les chats sont tolérés à
					condition d’être tenus en laisse ou dans des paniers jusqu’aux appartements. Il est interdit de
					laisser les chiens déposer leurs excréments dans les parties communes (pelouses...). Tous dégâts
					ou dégradations qu’ils pourraient causer seront à la charge de leur propriétaire.
				</p>
			</div>
			<div className="chapter" id="fenetres-balcons-facade">
				<h2>Fenêtres – balcons - façades</h2>
				<p>
					Il ne pourra être mis aux fenêtres ni objet, ni linge, ni couverture, ni couette, même
					momentanément, à l’exception des vases à fleurs qui devront être fixés solidement et reposer sur
					des dessous étanches. Les barbecues à charbon sont strictement interdits. Il est strictement
					interdit de jeter des objets par les fenêtres (miettes, mégots de cigarettes...) : pensez à vos
					voisins des rez-de-chaussée et rez-de-jardin.
				</p>
			</div>
			<div className="chapter" id="encombrements-degradations-dechets">
				<h2>Encombrements – dégradations - déchets</h2>
				<p>
					Chaque occupant sera tenu de déposer ses propres déchets ménagers dans des sacs étanches et
					fermés, regroupés dans les conteneurs mis à disposition. Il est interdit d’y déposer des objets
					encombrants. Tout dégât causé par l’un des propriétaires ou locataires dans les parties communes
					de l’immeuble, notamment lors des emménagements et déménagements (qui ne devront se faire que de
					jour), doit être signalé immédiatement et sera réparé par les soins du syndic et aux frais des
					copropriétaires ou locataires concernés. Le syndic aura les pouvoirs les plus étendus pour faire
					respecter cette clause. Les verres ne doivent pas être déposés dans les locaux poubelles mais
					dans les conteneurs à verre prévus à cet effet situés sur le parking rue de la Richardière.
					Les parties communes (sauf dans les locaux prévus à cet effet) notamment les entrées et couloirs
					ne doivent en aucun cas être encombrées par le dépôt, même momentané, des voitures d’enfants,
					cycles, sacs poubelles ou tout autre objet. Les passages, vestibules, escaliers, couloirs,
					entrées devront être laissés libres en tout temps. Les jeux dans les parties communes sont
					interdits, y compris sur les espaces verts. Le syndic et le conseil syndical dégagent toute
					responsabilité en cas d’accident. Il est formellement interdit de jouer au ballon ou à la balle
					de tennis contre les murs de l’immeuble.
				</p>
			</div>
			<div className="chapter" id="emmenagements-demenagements-travaux">
				<h2>Emménagements – déménagements - travaux</h2>
				<p>
					Merci de respecter en cas de travaux, déménagements ou emménagements les espaces communs&nbsp;:
					tels que les halls d’entrée, le respect des meubles en bois exotique, les ascenseurs, les
					escaliers, les paliers des différents étages. A charge des entreprises ou des résidents de
					nettoyer toutes traces et d’ôter tous gravats après leur passage.
				</p>
			</div>
			<div className="chapter" id="abords-exterieurs-espaces-verts">
				<h2>Abords extérieurs Espaces verts</h2>
				<p>
					Les occupants sont tenus de respecter les espaces verts et les plantations.
				</p>
				<p>
					Parkings : les parkings ne pourront servir qu’au stationnement des automobiles et motocyclettes.
					Les usagers doivent respecter les emplacements de parkings qui leur sont réservés. Pour le respect
					de chaque résident, utiliser de préférence son garage ou sa place de parking réservée afin de
					rendre disponible le plus possible de places de parking pour les résidents ou leurs invités et
					éviter tout stationnement gênant.
				</p>
			</div>
			<div className="chapter" id="assurance">
				<h2>Assurance</h2>
				<p>
					Chaque occupant : Propriétaire ou locataire doit souscrire un contrat d'assurance garantissant
					l'incendie, le dégât des eaux et les autres risques locatifs.
				</p>
				<p>
					En cas de panne ou d’urgence
					Les occupants pourront contacter le SYNDIC au 02.99.35.53.53 ou syndic@domeos.net.
				</p>
			</div>
		</div>
	);
}

export default Reglement;
import React from 'react';

import Map from "../components/Map";

import './Livret.scss';
import imgEnv1 from "../assets/environnement-1.jpg";
import imgEnv2 from "../assets/environnement-2.jpg";
import imgEnv3 from "../assets/environnement-3.jpg";

function Livret() {
  return (
    <div className="page livret" id="livret-d-accueil">
      <h1 id="top">Livret d'accueil</h1>
      <div className="chapter" id="la-residence-et-son-environnement">
        <h2>La résidence et son environnement</h2>
        <p>
          <img src={imgEnv1} className="img-at-left" alt="La résidence vue du ciel" />La résidence « Georges Clemenceau » située à proximité de la station de métro
          Clémenceau, face à l’Hôtel de RENNES Métropole, est enserrée entre le boulevard
          de l’Yser, l’avenue Henri Fréville et le boulevard Clémenceau. Ses bâtiments bordent
          un square qui propose une aire de jeu et des espaces verts. Ce dernier est public tout
          comme le parking extérieur. Les espaces verts sont entretenus par les services de la
          Ville.
        </p>
        <p>
          Cette résidence a été construite en 1973/1974 par le cabinet d’architectes PRUNET.
          Elle est composée de deux copropriétés distinctes qui emploient un gardien
          commun. Votre copropriété du 43 et 45 boulevard de l’Yser regroupe 96
          appartements.
        </p>
        <p>
          Le dernier ravalement de la résidence a été effectué en 2016.
        </p>
        <div className="side-by-side-img">
          <img src={imgEnv2} alt="La résidence vue du ciel" />
          <img src={imgEnv3} alt="La résidence vue du ciel" />
        </div>
        <p>Votre adresse postale est la suivante&nbsp;: </p>
        <div className="address">
          Résidence Clemenceau <em>(facultatif)</em><br />
          43 ou 45 boulevard de l’Yser,<br />
          Numéro d’appartement <em>(La Poste demande de le préciser)</em><br />
          35200 RENNES
        </div>
      </div>
      <div className="chapter" id="acces-et-usage-des-espaces-communs">
        <h2>Accès et usage des espaces communs</h2>
        <div className="map-wrapper">
          <Map />
        </div>
        <h3>Les halls d’entrée</h3>
        <p>
          La possession d’un badge est nécessaire pour pénétrer dans les halls. Ce badge est
          commun au 43 et au 45. Si vous avez besoin d’un badge supplémentaire, vous devez
          en faire la demande au gardien.
        </p>
        <p>
          Les étiquettes des noms sur les sonnettes et les boites aux lettres sont normalisées
          et apposées par le gardien dans le mois qui suit l’arrivée de chaque nouvel occupant.
          Des étiquettes temporaires bien lisibles peuvent être apposées en attendant. En
          revanche, aucun autre autocollant permanent ne peut être collé à votre initiative.
        </p>
        <p>
          Dans le hall, se trouve la vitrine d’affichage du syndic dans laquelle vous pourrez lire
          les numéros des entreprises de maintenance à appeler en cas d’urgence et en
          l’absence du gardien (cf. point 5).
        </p>
        <p>
          Vous avez la possibilité d’afficher des annonces personnelles sur le panneau prévu à
          cet effet.
        </p>
        <h3>Les garages situés en sous-sol</h3>
        <p>
          <b>Accès</b>&nbsp;: de l’intérieur de la résidence, l’accès se fait avec votre badge principal. De l’extérieur, elle se fait, sur toutes les portes, avec votre télécommande de garage. Il est aussi possible d’actionner toutes les portes du garage avec la clé du local poubelle.
        </p>
        <p>
          <b>Le sous-sol est accessible par trois portes</b>&nbsp;: Boulevard de l’Yser, Boulevard Clémenceau et Square de la Richardais.
        </p>
        <p>
          <b>Vidéo surveillance</b>&nbsp;: la partie de garages située sous le bâtiment 1 et 3 boulevard Henri Fréville est placée sous vidéo surveillance.
        </p>
        <p>
          <b>Voitures électriques</b>&nbsp;: Vous avez la possibilité de formuler une demande de branchement de votre box ou emplacement auprès de la société suivante référencée par le syndic&nbsp;: Zeplug  - <a href="tel:09 72 15 05 99">09 72 15 05 99</a> – <a href="https://www.zeplug.com" target="_blank" rel="noreferrer">www.zeplug.com</a>.
        </p>
        <h3>Les locaux poubelles</h3>
        <p>
          L’accès s’effectue par l’extérieur (voir plan) avec la clé dédiée.
        </p>
        <p>
          Vous y déposez vos ordures dans les poubelles différenciées selon les règles du tri sélectif en usage à Rennes Métropole.
        </p>
        <p>
          Vous avez la possibilité d’y déposer vos journaux. Ils sont récupérés par une entreprise de recyclage.
        </p>
        <p>
          Aucun verre et encombrant ne peut être déposé ici. Le tri du verre s’effectue directement au container (voir plan).
        </p>
        <p>
          A titre indicatif, un compost partagé (entretenu par les usagers et des bénévoles du quartier et géré par Rennes Métropole) est installé sur la droite du parking à l’entrée de la coulée verte qui vous conduit au boulevard de l’Yser.
        </p>
        <h3>Les garages à vélos</h3>
        <p>
          Vous avez à votre disposition quatre garages dont un propose un accès par le sous-sol. L’accès aux autres s’effectue par l’extérieur (voir plan).
        </p>
        <p>
          Tous les ans, un enlèvement des vélos inutilisés est organisé par le syndic. Vous serez prévenu par un avis dans votre boite aux lettres.
        </p>
        <h3>Les paliers et les celliers</h3>
        <p>
          La circulation dans les paliers et les couloirs ne doit être entravée par aucun objet (vélo, trottinette, poussette, etc.).
        </p>
        <p>
          De même, pour faciliter l’entretien des parties communes, il vous est demandé de bien vouloir ne pas mettre votre paillasson à l’extérieur de votre appartement.
        </p>
        Un vide ordure est disponible sur votre palier, vous pouvez y jeter, dans un sac fermé, vos petits déchets non recyclables.
        <p>
          Les celliers sont sécurisés par un verrou extérieur, veillez à le fermer après chaque passage.
        </p>
      </div>
      <div className="chapter" id="chauffage-eau-electricite">
        <h2>Chauffage, eau, électricité dans les parties privatives</h2>
        <p>
          <b>CHAUFFAGE</b>&nbsp;: Le système de chauffage de l’immeuble est collectif. Cependant, afin de mettre
          l’immeuble en conformité avec la loi, une individualisation des frais avec pose de capteurs
          individuels a été réalisée fin 2022. Vous trouvez donc sur chacun de vos radiateurs un boitier
          scellé et inviolable qui mesure votre consommation individuelle. Vous pouvez réguler cette dernière
          en actionnant les robinets de réglage de vos radiateurs qui gèrent le débit d’eau chaude à
          l’intérieur.
        </p>
        <p>
          Attention: la mise en route de la station de chauffage collective est, par décision de l’assemblée
          générale des copropriétaires, mise en route le 15 octobre et arrêtée le 15 mai. Vous n’avez aucune
          manipulation individuelle à effectuer sur vos radiateurs lors de la mise en service du chauffage.
          En cas de mauvaise montée en chauffe ou de robinets grippés, toute intervention doit impérativement
          passer par l’intermédiaire du syndic de copropriété, y compris la purge du trop-plein d’air.
        </p>
        <p>
          <b>EAU CHAUDE – EAU FROIDE</b>&nbsp;: chaque appartement dispose d’un compteur d’eau chaude et d’eau froide qui
          font l’objet d’un télérelevé trimestriel. Les frais d’eau sont inclus au tantième dans les charges
          collectées par le syndic et font l’objet d’une régulation en janvier après l’approbation du budget.
        </p>
        <p>
          <b>Attention</b>&nbsp;: l’installation sanitaire date de 1976. Aussi, certains tuyaux PVC sont fragilisés.
          Certains robinets peuvent être grippés, vous devez donc manipuler leur fermeture/ouverture avec la
          plus grande vigilance pour ne pas risquer des casses tuyaux et les fuites d’eau en conséquence.
          En cas, de résistance, nous vous invitons à faire appel à un professionnel.
        </p>
        <p>
          <b>ÉLECTRICITÉ</b> : chaque appartement est relié à un compteur Linky qui se trouve à proximité dans les
          placards des couloirs ou des selliers. Les factures sont envoyées directement par votre opérateur
          et ne sont donc pas intégrées aux charges collectées par le syndic.
        </p>
        <p>
          Les travaux électriques réalisés à votre charge dans les parties privatives hors appartement c’est-à-dire les selliers et garages doivent faire l’objet d’une demande au syndic. Ce dernier vous indiquera les contraintes et règles à suivre.
        </p>
      </div>
      <div className="chapter" id="realisation-de-travaux">
        <h2>Réalisations de travaux dans les parties privatives</h2>
        <p>
          Les travaux privatifs qui auraient des incidences sur la copropriété nécessitent une demande adressée au syndic; cette demande sera présentée
          ou soumise à une délibération de l’AG : il faut donc l’anticiper.
        </p>
        <p>
          Les travaux qui touchent aux huisseries extérieures ou aux volets nécessitent le respect des coloris
          d’origine et pour ce qui concerne les baies des portes-fenêtres, le respect des 5 battants.
          Vous pourrez obtenir le nuancier des couleurs retenues par la copropriété en vous adressant au syndic.
        </p>
        <p>
          La copropriété attire votre attention sur les travaux de plomberie qui nécessitent des précautions
          particulières. Si ces travaux nécessitent une coupure du circuit d’eau chaude ou de chauffage,
          il vous est demandé de prendre contact, vous-même ou votre artisan, avec le prestataire en charge
          du chauffage dans la résidence (actuellement ENGIE- Home services).
          Aucune intervention en relation avec les systèmes communs (chauffage, eau chaude, eau froide, ...)
          ne peut être faite par une personne ou entreprise non agréée.
        </p>
        <p>
          Merci d’éviter les bricolages parfois périlleux, la configuration du réseau nécessite des
          précautions particulières. Vous trouverez par voie d’affichage les coordonnées complètes de ce
          prestataire.
        </p>
        <p>
          Pensez enfin que ces travaux occasionnent du bruit. Merci d’en informer les voisins proches et de
          respecter les heures légales d’intervention (cf. règlement en annexe).
        </p>
      </div>
      <div className="chapter" id="pannes-dysfonctionnements-anomalies">
        <h2>Pannes, dysfonctionnements, anomalies, etc.</h2>
        <p>
          Si vous constatez une panne, un dysfonctionnement ou une anomalie dans les espaces communs,
          signalez-le simplement au syndic qui se chargera d’apporter la réponse appropriée au problème.
          Seul le syndic est habilité à commander une intervention qu’elle soit effectuée par le gardien ou
          un intervenant extérieur.
        </p>
        <p>
          En cas d’absence de ce dernier (indiquée par voie d’affichage dans les halls d’entrée) vous pouvez
          vous adresser directement au syndic aux heures de bureaux ou directement aux entreprises affichées
          dans le hall en dehors de ces heures et en cas d’urgence.
        </p>
        <p>
          Il vous revient en cas de problème dans votre appartement et de risque de conséquences pour la
          copropriété, de le signaler au gardien pour une action immédiate ou au syndic pour le traitement
          des conséquences qui peuvent en résulter.
        </p>
      </div>
      <div className="chapter" id="le-concierge-gardien">
        <h2>Le concierge-gardien</h2>
        <p>
          Le concierge-gardien de la résidence est M. HOCHET.
        </p>
        <p>
          Il est en charge de la bonne tenue et de l’entretien général de la résidence : nettoyage de
          certaines parties communes, gestion des déchets ménagers et réalisation de petits travaux.
        </p>
        <p>
          Vous pouvez le contacter à sa loge (voir plan) aux heures de permanence : tous les jours
          (sauf le week-end) de <strong>12H15 à 12H45 et de 18h00 à 19H00</strong>.
        </p>
        <p>
          En dehors de ces permanences, vous pouvez le contacter sur son portable au <a href="tel:06 69 45 32 80"><strong>06 69 45 32 80</strong></a>.
          Nous vous invitons à laisser un message précisant les raisons de votre appel.
        </p>
        <p>
          En cas d’absence du gardien, il est fait appel à une société externe pour assurer le ramassage
          des ordures ainsi que l’entretien des locaux poubelles et de certains espaces collectifs.
        </p>
        <p>
          Un(e) agent de service salarié(e) de la copropriété assure l’entretien des halls d’entrée,
          des paliers et des couloirs.
        </p>
      </div>
      <div className="chapter" id="emmenagement-et-demenagement">
        <h2>Emménagement et déménagement</h2>
        <p>
          Lors de votre arrivée ou de votre départ définitif de la Résidence, il vous est demandé de prévenir
          impérativement le concierge de votre date et des modalités de votre emménagement ou déménagement.
        </p>
      </div>
    </div>
  );
}

export default Livret;
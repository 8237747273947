import React from 'react';

import './NouveauxProprios.scss';
import mosaique from "../assets/mosaique.jpg";

function NouveauxProprios() {

	return (
		<div className="page nouveaux-proprios" id="nouveaux-proprietaires">
			<h1 id="top">A L’ATTENTION DES NOUVEAUX PROPRIETAIRES</h1>
			<img src={mosaique} alt="mosaïque" className="mosaic"/>
			<div className="chapter" id="assemblee-generale-coproprietaires">
				<h2>Assemblée de copropriété</h2>
				<p>
					L'instance constitutive de la co-propriété est l'Assemblée Générale Ordinaire des co-propriétaires,
					instance annuelle qui se tient en décembre. Votre présence y est indispensable pour participer aux
					décisions d'entretien, de travaux à venir...
				</p>
				<p>
					L'Assemblée Générale élit annuellement un conseil syndical qui veille au suivi de la mise en
					oeuvre des décisions prises en AG et est en même temps force de proposition.
				</p>
				<p>
					L'Assemblée Générale donne mandat à un syndic pour assurer la gestion de la co-propriété.
				</p>
				<p>
					L'Assemblée générale ordinaire de décembre 2020 a désigné, pour 3 ans, en qualité de
					syndic la société DOMEOS, 2 rue Pierre-Joseph Colin 35000 (<a href="tel:02 99 35 53 53">02 99 35 53 53</a>,&nbsp;
					<a href="mailto:syndic@domeos.net">syndic@domeos.net</a>).
				</p>
			</div>
			<div className="interlude"></div>
			<div className="chapter" id="espace-personnel">
				<h2>Espace personnel</h2>
				<p>
					Un espace personnel donnant accès à ses propres comptes et aux documents concernant
					la co-propriété est accessible sur le site <a href="www.domeos-syndic.net">www.domeos-syndic.net</a>.
				</p>
			</div>
			<div className="interlude"></div>
			<div className="chapter" id="charges">
				<h2>Charges</h2>
				<p>
					Vos charges seront de deux ordres :
				</p>
				<ul>
					<li>les appels à provision pour les charges de co-propriété. Elles sont adressées
						trimestriellement.</li>
					<li>les appels pour travaux à une périodicité décidée en Assemblée Générale.</li>
				</ul>
				<p>
					Les règlements peuvent s'effectuer sur le site dédié et, après contact avec le syndic,
					être prélevés automatiquement ou mensualisés.
				</p>
			</div>
			<div className="interlude"></div>
			<div className="chapter" id="visite-mensuelle-syndic">
				<h2>Visite mensuelle</h2>
				<p>
					Enfin, signalons dans le fonctionnement de la co-propriété que, dans le contrat que nous
					avons avec DOMEOS, est prévue une visite mensuelle de la co-propriété, le dernier vendredi
					de chaque mois à 9h 30, où il vous est possible de rencontrer pour toute question, un
					mandataire du syndic. Ordinairement un membre du conseil syndical est présent.
				</p>
			</div>
			<div className="chapter" id="liens-avec-copro-ab">
				<h2>Les liens avec la co-propriété A/B de la Résidence</h2>
				<p>
					Chaque co-propriété (cf. la partie1 du livret d'accueil) a son fonctionnement propre&nbsp;:
					Assemblée Générale, conseil syndical et depuis 2018, chacune est administrée par un syndic
					différent.
				</p>
				<p>
					Malgré des préoccupations communes à l'ensemble de la Résidence (en particulier, le
					gardien, le chauffage, les sous-sols...), il n'existe pas actuellement d'entité juridique
					regroupant les deux co-propriétés. La résolution des questions soulevées ou des chantiers
					partagés se traite par des rencontres ponctuelles des deux conseils syndicaux et par une
					collaboration des deux syndics.
				</p>
			</div>
		</div>
	);
}

export default NouveauxProprios;
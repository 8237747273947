import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './style/index.scss';
import Layout from './Layout';
import reportWebVitals from './reportWebVitals';

import Home from './pages/Home';
import Livret from './pages/Livret';
import Reglement from './pages/Reglement';
import NouveauxProprios from './pages/NouveauxProprios';
// import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="livret-d-accueil" element={<Livret />} />
        <Route path="reglement-interieur" element={<Reglement />} />
        <Route path="nouveaux-proprietaires" element={<NouveauxProprios />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import 'leaflet/dist/leaflet.css';

import { useState, useMemo, createRef } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";

import './Map.scss';

import trashImg from "../assets/icons/trash.png";
import publicParkingImg from "../assets/icons/public-parking.jpeg";
import bikeParkImg from "../assets/icons/bike-park.png";
import guardHutImg from "../assets/icons/guard-hut.png";
import parkAccessImg from "../assets/icons/park-access.png";
import greenRecycleImg from "../assets/icons/green-recycle.png";

import { X, List } from "react-bootstrap-icons";

function Map() {
	const icons: any = useMemo(() => [], []);
  const iconImgs = useMemo(() => [
    {
      img: trashImg,
      legend: "Local poubelle"
    }, {
      img: publicParkingImg,
      legend: "Parking public"
    }, {
      img: bikeParkImg,
      legend: "Local à vélo"
    }, {
      img: guardHutImg,
      legend: "Loge du gardien"
    }, {
      img: parkAccessImg,
      legend: "Accès aux garages"
    }, {
      img: greenRecycleImg,
      legend: "Lieu de compostage"
    }
  ], []);

  for (let iconImg of iconImgs) {
    icons.push(L.icon({
      iconUrl: iconImg.img,
      iconSize: iconImg.img === bikeParkImg ? [20, 20] : 
                iconImg.img === guardHutImg ? [30, 30] : [26, 26],
      className: 'map-icon',
      tooltipAnchor: [12, 0]
    }))
  }

  var markers: {
    position: [number, number],
    icon: any,
    iconImg: string,
    legend: string,
    shortLegend: string,
    id: number
  }[] = useMemo(() => [
    {
      position: [48.09254,-1.67250],
      icon: icons[3],
      iconImg: iconImgs[3].img,
      legend: "Loge du gardien (rez-de-chaussée du 45)",
      shortLegend: "RdC. du 45",
      id: 6
    },
    {
      position: [48.09261,-1.67314],
      icon: icons[0],
      iconImg: iconImgs[0].img,
      legend: "Local poubelles du 43",
      shortLegend: "du 43",
      id: 0
    },
    {
      position: [48.09264,-1.67260],
      icon: icons[0],
      iconImg: iconImgs[0].img,
      legend: "Local poubelles du 45",
      shortLegend: "du 45",
      id: 1
    },
    {
      position: [48.09261,-1.67301],
      icon: icons[2],
      iconImg: iconImgs[2].img,
      legend: "Local vélo du 43 (rez-de-chaussée)",
      shortLegend: "du 43 (RdC.)",
      id: 3
    },
    {
      position: [48.09261,-1.67276],
      icon: icons[2],
      iconImg: iconImgs[2].img,
      legend: "Local vélo du 45 (rez-de-chaussée)",
      shortLegend: "du 45 (RdC.)",
      id: 4
    },
    {
      position: [48.09250,-1.67265],
      icon: icons[2],
      iconImg: iconImgs[2].img,
      legend: "Local vélo au sous-sol",
      shortLegend: "au sous-sol",
      id: 5
    },
    {
      position: [48.09334,-1.67296],
      icon: icons[4],
      iconImg: iconImgs[4].img,
      legend: "Accès garages voitures bd. Clemenceau (Résidence A&B)",
      shortLegend: "bd. Clemenceau (Résidence A&B)",
      id: 7
    },
    {
      position: [48.09273,-1.67244],
      icon: icons[4],
      iconImg: iconImgs[4].img,
      legend: "Accès garages voitures rue de la Richardière",
      shortLegend: "rue de la Richardière",
      id: 8
    },
    {
      position: [48.09232,-1.67241],
      icon: icons[4],
      iconImg: iconImgs[4].img,
      legend: "Accès garages voitures bd. de l'Yser",
      shortLegend: "bd de l'Yser",
      id: 9
    },
    {
      position: [48.09304,-1.67244],
      icon: icons[1],
      iconImg: iconImgs[1].img,
      legend: "Parking public",
      shortLegend: "rue de la Richardière",
      id: 2
    },
    {
      position: [48.09276,-1.67224],
      icon: icons[5],
      iconImg: iconImgs[5].img,
      legend: "Lieu de compostage",
      shortLegend: "rue de la Richardière",
      id: 10
    }
  ], [iconImgs, icons]);

  const markerRefs = useMemo(() => {
		const refs: {
      [key: number]: any,
    } = {};
		markers.forEach((marker) => {
			refs[marker.id] = createRef();
		})
		return refs;
	}, [markers]);

  var groupedLegends = markers.reduce((acc: Record<string, any>, curr) => {
    if (!acc[curr.iconImg]) {
      acc[curr.iconImg] = {
        icon: curr.iconImg,
        legend: iconImgs.find(i => i.img === curr.iconImg)?.legend,
        markers: [curr]
      };
    } else {
      acc[curr.iconImg].markers.push(curr);
    }
    return acc;
  }, {})

  const [selectedMarker, setMarker] = useState<any>(null);
  const [legendsClass, setLegendsClass] = useState<any>('');

  function selectMarker(marker: any) {
    if (selectedMarker) {
      markerRefs[selectedMarker.id].current.openTooltip();
    }
    setMarker(marker);
    closeLegends();
    setTimeout(() => {
      markerRefs[marker.id].current.openTooltip();
    })
  }

  function closeMarker(marker: any) {
    setMarker(null);
    closeLegends();
    markerRefs[marker.id].current.closeTooltip();
  }

  function toogleLegendsClass() {
    setLegendsClass(legendsClass ? '' : 'displayed');
  }
  function closeLegends() {
    setLegendsClass('');
  }

	return (
<div className="map-container">
  <MapContainer center={[48.09288,-1.67310]} zoom={722} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {
      markers.map(marker => { 
        return !selectedMarker || selectedMarker.id === marker.id ? <Marker position={marker.position} icon={marker.icon} ref={markerRefs[marker.id]}>
          <Tooltip>
            {marker.legend}
          </Tooltip>
          </Marker> : ''
      })
    }
  </MapContainer>
  <div className={`map-legends ${legendsClass}`}>
    {
      Object.values(groupedLegends).map((l, i) => {
        return <div className="map-legend" key={i}>
            <div className="map-legend-header"><img src={l.icon} alt={l.legend}/> <span className="map-legend-text">{l.legend}</span></div>
            <div className="map-legend-markers"> 
            {
              l.markers.map((marker: any) => {
                return <span className={'map-legend-marker ' + (selectedMarker && selectedMarker.id === marker.id ? 'selected' : '')}
                             onClick={() => {selectMarker(marker); }}>
                        {marker.shortLegend}
                        {selectedMarker && selectedMarker.id === marker.id ? <span className="map-legend-marker-close" onClick={(e) => {closeMarker(marker); e.stopPropagation();}}><X/></span> : ''}
                      </span>
              })
            }
            </div>
          </div>
      })
    }
    <div className="map-legends-icon" onClick={toogleLegendsClass}><span className="map-legends-icon-text">Légendes</span></div>
  </div>
</div>
	);
}

export default Map;
import './Logo.scss';
import logImg from "./../assets/logo192.png";

function Logo() {
	return (
		<div className="logo">
			<img src={logImg} alt="logo" />
			<div className="title-wrapper">
				<div className="title">
					<span className="accent-md number n43">43</span><span className="accent number n45">45</span> <span className="accent-light">Résidence</span><br />
					<span className="accent-md">Georges</span><br />
					<span className="accent">Clemenceau</span>
				</div>
				<div className="sub-title">43 <span className="esperluette">&</span> 45 boulevard de l'Yser, à Rennes</div>
			</div>
		</div>
	);
}

export default Logo;
import { useState, useEffect, useCallback } from 'react';
export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' ;
type InternalBreakpoint = {bp: Breakpoint, from: number};

const breakpoints: InternalBreakpoint[] = [
	{ bp: 'xs', from: 0},
	{ bp: 'sm', from: 576},
	{ bp: 'md', from: 768},
	{ bp: 'lg', from: 992},
	{ bp: 'xl', from: 1200},
	{ bp: 'xxl', from: 1400}
];

function getCurrentBreakpoint(width: number): InternalBreakpoint {
	let currentBreakpoint;
	for (const bp of breakpoints) {
		if (width >= bp.from) {
			currentBreakpoint = bp;
		} else {
			break;
		}
	}
	return currentBreakpoint || breakpoints[0];
}

export const mediaBreakpointUp = () => {

}

function mediaBreakpoint(width: number, breakpoint: Breakpoint, type: 'up' | 'down' | 'only') {
	const targetBreakpoint = breakpoints.find(bp => bp.bp === breakpoint);
	if (!targetBreakpoint) return false;
	const targetBreakpointIndex = breakpoints.indexOf(targetBreakpoint);
	const nextBreakpoint = breakpoints[targetBreakpointIndex + 1];

	console.log('targetBp: ' + targetBreakpoint.from + ', nextBp: ' + nextBreakpoint.from + ', current: ' + width)

	switch (type) {
		case 'only': return targetBreakpoint.from <= width && width < nextBreakpoint.from;
		case 'up': return targetBreakpoint.from <= width;
		case 'down': return width < nextBreakpoint.from;
	}
}

/** Get Media Query Breakpoints in React */
const useBreakpoint = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	
	useEffect(() => {
		let currentTimeout: NodeJS.Timeout;
		const calcInnerWidth = () => {
			if (currentTimeout) {
				clearTimeout(currentTimeout);
			}
			currentTimeout = setTimeout(() => {
				setWindowWidth(window.innerWidth);

				
			}, 200);
		};
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, [windowWidth]);
	
	return {
		mediaBreakpointUp: (bp: Breakpoint) => mediaBreakpoint(windowWidth, bp, 'up'),
		mediaBreakpointDown: (bp: Breakpoint) => mediaBreakpoint(windowWidth, bp, 'down'),
		mediaBreakpointOnly: (bp: Breakpoint) => mediaBreakpoint(windowWidth, bp, 'only')
	};
};

export default useBreakpoint;
import { Outlet } from "react-router-dom";
import { useState, useEffect } from 'react';
import './Layout.scss';

import useBreakpoint from "./components/UseBreakpoint";
import MainNav from './components/MainNav';
import { List } from "react-bootstrap-icons";

/**
 * TODO let menu allways displayed on large screens ?
 * TODO add link, tel number, images everywhere it's possible
 * @returns
 */
const Layout = () => {
  const navSwipedClassName = 'swiped';

  const breakPoint = useBreakpoint();
  const [wscroll, setWscroll] = useState(0);
  const [navClass, setNavClass] = useState('');
  const [navOpenByUser, setNavOpenByUser] = useState(false);

  function toogleNav() {
    setNavOpenByUser(!navOpenByUser);
  }

  useEffect(() => {
    if (!navOpenByUser && (wscroll - (window.innerHeight * 1 / 9) > 0 || breakPoint.mediaBreakpointDown('md'))) {   
      setNavClass(navSwipedClassName);
    } else {
      setNavClass('');
    }

    const handleScroll = (event: Event) => {
      setWscroll(window.scrollY);
      setNavOpenByUser(false);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [wscroll, breakPoint, navSwipedClassName, navOpenByUser]);

  return (
    <div className="main-container">
      <div className="main-nav-placeholder"></div>
      <div className={`main-nav-container ${navClass}`}>
        <div className="nav-icon" onClick={toogleNav}>
          <List />
          <div className="nav-icon-title">MENU</div>
        </div>
        <MainNav onItemClick={toogleNav} />
      </div>
      <Outlet />
    </div>
  )
};

export default Layout;
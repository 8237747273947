import { HashLink as Link } from 'react-router-hash-link';

import Logo from "../components/Logo";
import Map from "../components/Map";

import './Home.scss';

function Home() {

  return (
    <div className="home-page">
      <header className="main-header" id="main-header">
        <div className="title-container">
          <Logo />
        </div>
      </header>
      <div className="home-content" id="a-la-une">
        <div className="home-block-container">
          <div className="home-block">
            <div className="home-block-inner">
              Bienvenue dans l'application dédiée à la résidence Georges Clémenceau, 43 & 45 boulevard de l'Yser.
              C'est sa toute première version, qui met à disposition :
              <ul>
                <li><Link to="/livret-d-accueil">Le livret d'accueil</Link></li>
                <li><Link to="/reglement-interieur">Le règlement de copropriété</Link></li>
                <li><Link to="/nouveaux-proprietaires">Une section à l'attention des nouveaux propriétaires</Link></li>
              </ul>
            </div>
          </div>
          <div className="home-block">
            <div className="home-block-inner">
              <strong>À venir</strong><br />
              Cette application a vocation à évoluer en offrant :
              <ul>
                <li>Dans un premier temps des nouvelles, des notes importantes et informations diverses de la part du conseil syndical</li>
                <li>Et dans un second, un espace d'échange et d'entraide entre les résidents, qui reste à inventer.</li>
                <li>A ce propos, les idées sont les bienvenues, n'hésitez pas à nous en faire part à <a href="mailto:residence.clemenceau4345@gmail.com">residence.clemenceau4345@gmail.com</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="chapter" id="plan-de-la-residence">
          <h2>Plan de la résidence</h2>
          <div className="map-wrapper">
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
